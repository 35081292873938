/* Global Styles */
:root {
  --primary-color: #17a2b8;
  --secondary-color: #c27380;
  --dark-color: #0a1416;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --success-color-light: #8cc298;
  --warning-color-light: #d3b97f;
  --danger-color-light: #d1929c;
  --disabled-color: #c4bbbb;
  --bg-color-gray-400: #f2f6f9;
  --bg-color-gray-500: #bacede;
  --bg-color-light-green: #b8d3be;
  --bg-color-light-yellow: #f3fca2;
  --bg-color-light-red: #e6bbbf;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.scroll-x {
  overflow-x: scroll;
}

.img-logo {
  content: url("./img/logo.svg");
  width: 40px;
}

.brand-logo {
  height: 35px
}

.brand-logo h1 {
  font-size: 1.5rem;
}

.logo-big {
  content: url("./img/logo.svg");
  width: 250px;
  margin-top: 50px;
}

.search-panel {
  display: flex;

}

.input-text {
  display: inline;
  /* width: 100%; */
  padding: 0.2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-family: inherit;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  resize: none;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.container-top {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
}

.container-bottom {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-bottom: 3rem;
}

.inline {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

/* .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
} */

#footer {
  position: fixed;
  height: 20px;
  bottom: 0px;
  /* left: 0px; */
  right: 20px;
  margin-bottom: 5px;
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer p {
  font-size: 0.75rem;
  color: #fff;
}

.footer-basic {
  padding: 30px 0;
  background-color: #ffffff;
  color: #4b4c4d;
}

.footer-home {
  padding: 10px;
  background-color: #ffffff;
  color: #4b4c4d;
  height: 20vh;
  border-top: solid 1px var(--dark-color);
  opacity: 0.9;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-home ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-home li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-home ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-home ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-home .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-home .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social>a:hover {
  opacity: 0.9;
}

.footer-home .social>a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 7px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.footer-home .copyright {
  margin-top: 7px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.medium {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.small {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.small2 {
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.tiny {
  font-size: 0.90rem;
  line-height: 1;
  justify-items: center;
}

.mini {
  font-size: 0.80rem;
  line-height: 1;
  justify-items: center;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.title-search-result {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

/* Search Container and Ctrl */
.search {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.textinput {
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  display: block;
}

/* Padding */
.p {
  padding: 0.5rem;
}

.p-04 {
  padding: 0.4rem;
}

.p-05 {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mg-top-1 {
  margin-top: 1rem;
}

/* Border Radius*/

.br-1 {
  border-radius: 1px;
}

.br-2 {
  border-radius: 2px;
}

.br-3 {
  border-radius: 3px;
}

.br-4 {
  border-radius: 4px;
}

.br-5 {
  border-radius: 5px;
}

/* Buttons */
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 4px;
}

.btn-small {
  font-size: 0.8rem
}

.btn-link {
  line-height: 1;
}

.btn-square {
  padding: 0.6rem 0.6rem;
}

.btn-disabled {
  background: var(--disabled-color);
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  width: 160px;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-warning,
.bg-warning,
.badge-warning,
.alert-warning {
  background: var(--warning-color-light);
  color: #fff;
}


.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn-secondary {
  background: var(--secondary-color);
  color: #fff
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.round-img-slow {
  border-radius: 2%;
  width: 250px;
  /*height: 250px;*/
  object-fit: contain;
}


.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.darklight-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
}

/* .darklight-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form input[type='number'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-family: inherit;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  resize: none;
}

.form input[type='checkbox'] {
  /* display: block;
  width: 100%;
  padding: 0.4rem;
  font-family: inherit;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  resize: none; */
  margin: 10px;
}

.input-text-grid {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-family: inherit;
  font-size: 1rem;
  border: 1px solid #ccc;
  resize: none;
}

/* .form input[type='submit'],
button {
  font: inherit;
} */

.form input[type='submit']:disabled {
  background: var(--disabled-color);
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}

.form .social-input i.fa-facebook {
  color: #3b5998;
}

.form .social-input i.fa-instagram {
  color: #3f729b;
}

.form .social-input i.fa-youtube {
  color: #c4302b;
}

.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table {
  overflow-x: scroll;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

.table tfoot td {
  background: var(--light-color);
}

.table tr .green {
  background-color: var(--bg-color-light-green);
}

.table tr .yellow {
  background-color: var(--bg-color-light-yellow);
}

.header-toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0.9;
}

/* Menubar */
.menubar {
  display: flex;
  justify-content: right;
  align-items: flex-start;
  /* margin-top: 3.7rem; */
  padding-right: 2.5rem;
  /* position: fixed; */
  position: relative;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--dark-color);
  opacity: 0.9;
}

.menubar ul {
  display: flex;
  white-space: nowrap;
}

.menubar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  /* position: fixed; */
  position: relative;
  z-index: 1;
  width: 100%;
  /* top: 0; */
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
  white-space: nowrap;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Landing Page */
.landing {
  position: relative;
  background-image: url('./img/showcase2.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Company */
.company {
  position: relative;
  background: url('./img/company.webp') no-repeat center center/cover;
  min-height: 100vh;
}

.company-inner {
  color: #fff;
  min-height: 100vh;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.company-container {
  margin-top: 4.0rem;
}

/* Conditions */
.conditions {
  position: relative;
  min-height: 100vh;
}

.conditions-inner {
  min-height: 100vh;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conditions-container {
  margin-top: 4.0rem;
}

.conditions-container p {
  margin-top: 1.2rem;
  line-height: 1.6;
}

/* Trips Page */

.trips-container {
  /* max-width: 1100px; */
  width: 100%;
  /* margin: auto; */
  overflow: hidden;
  padding: 0 6rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.trips-container-bottom {
  width: 100%;
  overflow: hidden;
  padding: 0 6rem;
  margin-bottom: 3rem;
}

.trips {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.2rem;
}

.trip-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'desc desc'
    'foot1 foot2';
  grid-gap: 1rem;
}

.trip-top {
  grid-area: top;
}

.trip-top img {
  width: 100%;
  margin: auto;
}

.trip-desc {
  grid-area: desc;
  padding: 1rem;
}

.trip-foot1 {
  grid-area: foot1;
}

.trip-foot2 {
  grid-area: foot2;
}

.trip-foot1,
.trip-foot2 {
  padding: 1rem;
}


/* Profiles Page */
.profile {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  align-items: top;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

/* Profile Page */
.profile-grid {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp edu'
    'github github';
  grid-gap: 1rem;
}

.profile-grid2 {
  display: grid;
  grid-template-areas:
    'top top'
    'about about'
    'exp exp'
    'github github';
  grid-gap: 1rem;
}

.profile-top {
  grid-area: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-top img {
  width: 250px;
}

.profile-top .icons a {
  color: #fff;
  margin: 0 0.3rem;
}

.profile-top .icons a:hover {
  color: var(--dark-color);
}

.profile-about {
  grid-area: about;
  text-align: center;
}

.profile-about .skills {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.profile-exp {
  grid-area: exp;
}

.profile-edu {
  grid-area: edu;
}

.profile-exp h2,
.profile-edu h2 {
  margin-bottom: 1rem;
}

.profile-exp>div,
.profile-edu>div {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: #ccc 1px dotted;
}

.profile-exp>div:last-child,
.profile-edu>div:last-child {
  border: 0;
}

.profile-exp p,
.profile-edu p {
  margin: 0.5rem 0;
}

.profile-github {
  grid-area: github;
}

.profile-github .repo {
  display: flex;
}

.profile-github .repo>div:first-child {
  flex: 7;
  flex-basis: 70%;
}

.profile-github>div:last-child {
  flex: 3;
  flex-basis: 20%;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post>div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}


/* Notebooks Styles */
/* @media (max-width: 900px) {
  .logo-big {
    width: 150px;
  }
} */


.alert-wrapper {
  position: fixed;
  top: 6rem;
  right: 2rem;
  display: inline-block;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* cards styles */
.cards {
  padding: 2rem;
}

.cards>ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.cards>ul>li {
  border: 1px solid #E2E2E2;
  border-radius: .5rem;
}

.cards>ul>li>figure {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  position: relative;
}

.cards>ul>li>figure>img {
  width: 100%;
}

.cards>ul>li>figure>figcaption {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, .7);
  width: 100%;
}

.cards>ul>li>figure>figcaption>h3 {
  color: white;
  padding: .75rem;
  font-size: 1.25rem;
}

.cards>ul>li>figure>figcaption>h3>a {
  color: white;
  padding: .75rem;
  font-size: 1.25rem;
}

.cards>ul>li>p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #666666;
}

.cards>ul>li>a {
  padding: .5rem 1rem;
  margin: .5rem;
}

.card-load {
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  position: relative;
}

.card-load>figure>img {
  /*width: 100%;*/
  width: 300px;
}


/* gral*/
.no-wrap {
  white-space: nowrap;
  min-width: max-content;
}

p {
  white-space: pre-wrap;
}

label {
  font-weight: 550;
  font-size: 1.1rem;
}

.label-medium {
  font-weight: 550;
  font-size: 1rem;
}

.link {
  cursor: pointer;
  margin: 0 0.25rem;
}

.footer2 {
  padding: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.rubber-stamp {
  background: var(--dark-color);
  color: #fff
}

/* end gral*/

/* Nav Calendar*/
/* .nav-months-new {
  border: #38a1f3 1px solid;
}

.nav-months-new ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.nav-months-new li {
  margin: 5px;
}

.nav-months-new a {
  width: 110px;
  text-align: center;
  padding: .3em 0.9em;
  text-decoration: none;
  color: #333;
  background: var(--white-color);
  border: #ccc solid 1px;
  cursor: pointer;
  border-radius: 4px;
} */

/***********************************/
.nav-months ul {
  float: left;
  list-style: none;
}

.nav-months li {
  float: left;
  margin: 3px;
  border-radius: 4px;
}

.nav-months a {
  width: 110px;
  text-align: center;
  float: left;
  font-size: 0.9rem;
  padding: .2em 0.7em;
  text-decoration: none;
  color: #333;
  background: var(--white-color);
  border: #ccc solid 1px;
  cursor: pointer;
  border-radius: 4px;
}

.nav-months a:hover,
nav a:focus {
  outline: 0;
  color: #fff;
  background: var(--dark-color);
}

.nav-months ul li a.active {
  color: #fff;
  background: var(--success-color);
}

.nav-months ul li a.disabled {
  color: #333;
  background: var(--light-color);
  border: #eee solid 1px;
}

/* End Nav Calendar*/

.highlight {
  color: white;
  background-color: #343a40;
  font-weight: 900;
  padding: 4px;
  opacity: 0.9;
}

.highlight2 {
  color: white;
  background-color: #F15A2A;
  font-weight: 900;
  padding: 4px;
  opacity: 0.9;
}

.footnote {
  font-size: 13px;
}

.text-link {
  font-size: 0.75rem;
  color: #fff;
}

/* @media (max-width: 768px) {
  .logo-big {
    width: 250px;
  }
  .x-large {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .large {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .medium {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }  

} */


.login-form {
  max-width: 400px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.form-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.form-button-noborder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
}

.crop {
  height: 200px;
  overflow: hidden;
  background-color: #bacede;
}

.sticker {
  background-color: #F15A2A;
  color: #ffffff !important;
  z-index: 0;
  position: absolute;
  float: right;
  margin-top: 20px;
  margin-left: 10px;
  transform: rotate(-5deg);
  padding: 0.2rem;
  border-radius: 3px;
}

/********************************************************************/
/* Notebooks Styles */
/********************************************************************/
@media (max-width: 1366px) {
  .logo-big {
    width: 200px;
    margin-top: 60px;
  }

  .x-large {
    font-size: 2.6rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  .large {
    font-size: 2.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  .medium {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .lead {
    font-size: 1.8rem;
  }
}

@media (max-width: 1024px) {
  .trips-container {
    /* max-width: 1100px; */
    width: 100%;
    /* margin: auto; */
    overflow: hidden;
    padding: 0 2rem;
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  .trips-container-bottom {
    width: 100%;
    overflow: hidden;
    padding: 0 2rem;
    margin-bottom: 3rem;
  }

  .trips {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.2rem;
  }
}

@media (max-width: 768px) {
  .trips {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.2rem;
  }

  .crop {
    height: 210px;
    overflow: hidden;
    background-color: #bacede;
  }
}

/* Mobile Styles */
@media (max-width: 700px) {
  .crop {
    height: 350px;
    overflow: hidden;
    background-color: #bacede;
  }

  .trips {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
  }

  .logo-big {
    width: 150px;
  }

  .container {
    margin-top: 8rem;
  }

  /* .landing-inner {
    margin-top: 4rem;
  } */

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 2rem;
  }

  .large {
    font-size: 1.6rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  /* Profiles Page */
  .profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile ul {
    display: none;
  }

  /* Profile Page */

  .profile-top img,
  .profile img {
    width: 200px;
    margin: auto;
  }

  .profile-grid {
    grid-template-areas:
      'top'
      'about'
      'exp'
      'edu'
      'github';
  }

  .profile-about .skills {
    flex-direction: column;
  }

  .dash-buttons a {
    display: block;
    width: 100%;
    margin-bottom: 0.2rem;
  }

  .post {
    grid-template-columns: 1fr;
  }

  .post a,
  .post button {
    padding: 0.3rem 0.4rem;
  }

  .round-img-slow {
    border-radius: 2%;
    width: 150px;
    /* height: 150px; */
  }

  .img-logo {
    /* content:url("./img/logo.svg"); */
    width: 30px;
  }

  .brand-logo h1 {
    font-size: 1.25rem;
  }

}

/* End Mobile Styles */

@media (max-width: 480px) {
  .fit-content {
    width: auto !important;
  }

  .trips-container {
    /* max-width: 1100px; */
    width: 100%;
    /* margin: auto; */
    overflow: hidden;
    padding: 0 1rem;
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  .trips-container {
    width: 100%;
    overflow: hidden;
    padding: 0 1rem;
    margin-bottom: 3rem;
  }

  .crop {
    height: 250px;
    overflow: hidden;
    background-color: #bacede;
  }

  .trips {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.2rem;

  }

  .trip-grid {
    display: grid;
    grid-template-areas:
      'top'
      'desc'
      'foot1'
      'foot2';
    grid-gap: 1rem;
    margin-top: 2rem;
  }
}

/* Deafult Margin & Padding */

.p-5 {
  padding: 3rem !important
}

/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

/*-- Margin Bottom --*/

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

/*-- margin left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

/*-- margin right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

/*-- Padding Top --*/

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

/*-- Padding Bottom --*/

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

/*-- Padding left --*/

.pl-0 {
  padding-left: 0px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

/*-- Padding right --*/

.pr-0 {
  padding-right: 0px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.width-100 {
  width: 100%;
}

/* Bootstrap inspired */
.rounded {
  border-radius: 0.375rem;
}

.bg-body-gray {
  background-color: var(--bg-color-gray-400);
  opacity: 0.8;
}

.bg-body-gray-500 {
  background-color: var(--bg-color-gray-500);
  /* opacity: 0.8; */
}

.fit-content {
  width: max-content;
}

.bg-success-light {
  background: var(--success-color-light);
  color: #fff;
  font-weight: 550;
}

.bg-warning-light {
  background: var(--warning-color-light);
  color: #fff;
  font-weight: 550;
}

.bg-danger-light {
  background: var(--danger-color-light);
  color: #fff;
  font-weight: 550;
}

.mark-danger {
  color: var(--danger-color);
  font-weight: 600;
}

/* fonts */
/* cyrillic-ext */
/* @font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCFPrEHJA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
} */

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCMPrEHJA.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCHPrEHJA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCGPrEHJA.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}